/**
 * Clinic post Template
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Blockfeature from "../components/blockfeature"
import Contactinformation from "../components/contactinformation"
import Ctabutton from "../components/ctabutton"
import Iframelogin from "../components/iframelogin"
import Layoutiframeclinic from "../components/layoutiframeclinic"
import Logo from "../components/logo"
import Seo from "../components/seo"
import "../styles/templates/clinicpost.scss"


/* Declaration function */
const ClinicPostTemplate = ({ data }) => {
  //Const data ACF
  const dataClinic = data.wpClinic
  const dataAcfClinic = dataClinic.acfPostClinic
  const dataAcfHomepage = data.wpPage.acfPageHome

  return (
    <Layoutiframeclinic>
      <Seo
          title={dataClinic.seo.title}
          description={dataClinic.seo.metaDesc}
          noindex="noindex"
        />
      <section className="section-login">
        <div className="content-login">
          <Logo color="white" />
          <Iframelogin paramClinic = {dataAcfClinic.paramClinic} />
        </div>
      </section>

      {/* Main content */}
      <section className="section-content-clinic">
        <div dangerouslySetInnerHTML={{ __html:data.options.acfPageOptions.descriptionClinic}} />
        <h1>{dataAcfHomepage.titleListFeatures}</h1>
        <div className="list-features flex-content">
          {dataAcfHomepage.repeaterFeatures.map((feature, i) => (
            <Blockfeature key={ `feature-${i}` } titleFeature={feature.titleFeature} textIcon={feature.iconFeature}/>
            )
          )}
        </div>
        <div className="block-price" dangerouslySetInnerHTML={{ __html:dataAcfClinic.iframeText}} />
      </section>

      <section className="section-contactinfos">
        <Contactinformation />
        <div className="cta-website">
          <Ctabutton ctaLabel="Visit PortalConnect Website" ctaLink="https://portalconnect.net" otherStyle="" />
        </div>
      </section>

    </Layoutiframeclinic>
  )

}

/* Export template informations */
export default ClinicPostTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
    query($id: Int!) {
      wpClinic(databaseId: {eq: $id}) {
        databaseId
        title
        acfPostClinic {
          paramClinic
          iframeText
        }
        seo {
          metaDesc
          title
        }
      }
      options: wpPage(slug: {eq: "options-website"}) {
        acfPageOptions {
          descriptionClinic
        }
      }
      wpPage(slug: {eq: "homepage"}) {
        acfPageHome {
          titleListFeatures
          repeaterFeatures {
            titleFeature
            iconFeature
          }
        }
      }
    }
`
